import { closeMenu } from "./header";

document.addEventListener("DOMContentLoaded", afterDOMContentLoaded);

function afterDOMContentLoaded() {
  const menuLinks = document.querySelectorAll("#main-menu a");

  registerLinkHandlers(menuLinks);
  registerCurrentSectionDetection(menuLinks);
}

function registerLinkHandlers(menuLinks: NodeListOf<Element>) {
  const menuBtn = document.getElementById("hamburger-menu");
  const contentCont = document.getElementById("main-content-cont");
  const menuCont = document.getElementById("main-menu");

  menuLinks.forEach((el) =>
    el.addEventListener(
      "click",
      () => closeMenu(menuBtn, contentCont, menuCont),
      { capture: true }
    )
  );
}

function registerCurrentSectionDetection(menuLinks: NodeListOf<Element>) {
  let infoScrollThresh = 0;
  let galleryScrollThresh = 0;
  let roadmapScrollThresh = 0;
  let teamScrollThresh = 0;

  const landingLink = document.getElementById("main-menu-link-landing");
  const infoLink = document.getElementById("main-menu-link-info");
  const galleryLink = document.getElementById("main-menu-link-gallery");
  const roadmapLink = document.getElementById("main-menu-link-roadmap");
  const teamLink = document.getElementById("main-menu-link-team");

  window.addEventListener("scroll", onWindowScroll, { passive: true });
  onWindowScroll();

  function onWindowScroll() {
    if (window.scrollY > teamScrollThresh) {
      setActiveLink(teamLink);
    } else if (window.scrollY > roadmapScrollThresh) {
      setActiveLink(roadmapLink);
    } else if (window.scrollY > galleryScrollThresh) {
      setActiveLink(galleryLink);
    } else if (window.scrollY > infoScrollThresh) {
      setActiveLink(infoLink);
    } else {
      setActiveLink(landingLink);
    }
  }

  function setActiveLink(linkEl: HTMLElement) {
    menuLinks.forEach((el) => el.classList.remove("selected"));
    linkEl.classList.add("selected");
  }

  const scrollActiveThresh = 300;

  const infoSection = document.getElementById("collection-info");
  const gallerySection = document.getElementById("feature-section-3");
  const roadmapSection = document.getElementById("roadmap-cont");
  const teamSection = document.getElementById("team");

  onWindowResize();
  window.addEventListener("resize", onWindowResize, { passive: true });

  function onWindowResize() {
    infoScrollThresh = infoSection.offsetTop - scrollActiveThresh;
    galleryScrollThresh = gallerySection.offsetTop - scrollActiveThresh;
    roadmapScrollThresh = roadmapSection.offsetTop - scrollActiveThresh;
    teamScrollThresh = teamSection.offsetTop - scrollActiveThresh;
  }

  setTimeout(() => {
    onWindowResize();
    onWindowScroll();
  }, 500);
}
